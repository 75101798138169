import React from 'react';

import {
    overwrite,
    section as sectionClass,
    grid,
    title as titleClass,
    description as descriptionClass,
    subtitle as subtitleClass,
    button as buttonClass,
    wrapper,
    iconCard,
    iconImage,
} from './icon-presentation.module.scss';

import { ISection } from '../../models/section.model';

import Section from '../hoc/section';
import { IMedia } from '@alterpage/gatsby-source-alterpress';
import { Image } from '@alterpage/gatsby-plugin-image';

export type IIconPresentationSection = ISection & {
    content: {
        images: {
            media: IMedia[];
        }[];
    };
};

interface IIconPresentationProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: IIconPresentationSection;
}

const IconPresentation: React.FC<IIconPresentationProps> = ({ className, TitleTag, section }) => {
    const { sectionId, content, css, style } = section;
    const { texts, images } = content;
    const [title, description] = texts;
    return (
        <Section
            sectionId={sectionId}
            className={`${sectionClass} ${className} ${overwrite}`}
            classes={{
                container: grid,
                title: titleClass,
                description: descriptionClass,
                subtitle: subtitleClass,
                button: buttonClass,
            }}
            TitleTag={TitleTag}
            title={title}
            subtitle={'COŚ EKSTRA'}
            description={description}
            css={css}
            style={style}
        >
            <div className={wrapper}>
                {images.map((image, index) => {
                    return (
                        <div className={iconCard} key={`icon-${index}`}>
                            <Image className={iconImage} media={image.media} />
                            <p>{texts[index + 2]}</p>
                        </div>
                    );
                })}
            </div>
        </Section>
    );
};

export default IconPresentation;
