// extracted by mini-css-extract-plugin
export var button = "icon-presentation-module--button--aefd6";
export var description = "icon-presentation-module--description--c61d2";
export var grid = "icon-presentation-module--grid--3c542";
export var iconCard = "icon-presentation-module--icon-card--fc293";
export var iconImage = "icon-presentation-module--icon-image--5c392";
export var overwrite = "icon-presentation-module--overwrite--122f9";
export var section = "icon-presentation-module--section--691af";
export var subtitle = "icon-presentation-module--subtitle--c93d1";
export var title = "icon-presentation-module--title--c2fa9";
export var wrapper = "icon-presentation-module--wrapper--e63cc";